import Link from "next/link";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { DualRingInner, DualRingLoader, TheButton } from "components";
import { useAppContext, withApp } from "contexts";

import ChakraModal from "components/TheComponents/Modal";
import { TwitchLoginLayout } from "components/public";
import { useDisclosure } from "@chakra-ui/react";
import LogoutLayout from "components/public/LogoutLayout";
import {
  AdaptiveLogo,
  DropNav,
  Logo,
  Profile,
  Header,
  Slide,
  DropDownBack,
  LogoutButton,
  MobileHeaderBlock,
  SignInButton,
  MobileMenu,
  TopHeaderMenu,
  MobileMenuItem,
  MobileMenuItemIcon,
  AuthBlock,
  MobileItems,
} from "./styles";
import NavLink from "./components/Navigation/NavLink";
import { filterNavItems } from "./utils/filterNavItems";
import StreamIndicator from "./components/StreamIndicator";
import { useUserClientActions } from "hooks/user/useCurrentUser";
import Image from "next/image";

import { ITopMenuItem, menuItems, addMenuItems, metaMenuItems, metaMobileMenuItems } from "./dto";
import { useShowPageLayoutContext } from "contexts/ShowPageLayoutContext";
import { useMediaQuery } from "react-responsive";
import { Burger } from "./components/icons";
import { useRouter } from "next/navigation";
import DesktopViewProfileMenu from "./components/HeaderProfile/components/DesktopViewProfileMenu";
import MobileViewProfileMenu from "./components/HeaderProfile/components/MobileViewProfileMenu";
import { useGetActiveNotificationList } from "hooks/notification/useGetActiveNotificationList";
import { NotificationType } from "types/notification";
import StreamLiveNotificationCard from "components/admin/notifications/StreamLiveNotificationCard";
import GenericNotificationCard from "components/admin/notifications/NotificationCard";
import CustomNotificationCard from "components/admin/notifications/CustomNotificationCard";
import TreasuryBanner from "components/treasury/TreasuryBanner";
import NewStreamerNotificationCard from "components/admin/notifications/NewStreamerCard";

export interface IHeaderComponent {
  id?: string;
}

const HeaderComponentPure: React.FC<IHeaderComponent> = () => {
  const {
    profile,
    isAdmin,
    liveStreams,
    accessURL,
    loginModalProps,
    profileProps,
    isFeatureEnabled,
    featureFlags,
  } = useAppContext();

  const router = useRouter();

  const isMobile = useMediaQuery({
    query: "(max-width: 1024px)",
  });

  const isSmallMobile = useMediaQuery({
    query: "(max-width: 500px)",
  });

  const isTablet = useMediaQuery({
    query: "(min-width: 501px) and (max-width: 1399px)",
  });

  const logoSrc = isMobile
    ? "/images/HeaderComponent/mobile_logo.svg"
    : "/images/HeaderComponent/logo.svg";

  const { setShowNavSidebar } = useShowPageLayoutContext();
  const { data, refetch } = useGetActiveNotificationList();

  const { logout, isLoadingUserInfo } = useUserClientActions();
  const { isLoginOpen, onOpenLogin, onCloseLogin } = loginModalProps;
  const { onCloseProfile } = profileProps;
  const { isOpen: isLogoutOpen, onOpen: onOpenLogout, onClose: onCloseLogout } = useDisclosure();
  const [items, setItems] = React.useState<ITopMenuItem[]>([]);
  const [itemKeys, setItemKeys] = React.useState<string[]>([]);
  const [activeDropdown, setActiveDropdown] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const itemsMenu = items.reduce<{
    interactive: ITopMenuItem[];
    nonInteractive: ITopMenuItem[];
  }>(
    (acc, item) => {
      item.isInteractive ? acc.interactive.push(item) : acc.nonInteractive.push(item);
      return acc;
    },
    { interactive: [], nonInteractive: [] }
  );

  const onNavItemClick = useCallback(() => {
    setActiveDropdown(null);
  }, []);

  const onToggleDropdown = useCallback(
    async (key: string) => {
      if (key === "dropdown-news" && activeDropdown !== key) {
        try {
          setIsLoading(true);
          await refetch();
        } finally {
          setIsLoading(false);
        }
      }
      setActiveDropdown((prev) => (prev === key ? null : key));
    },
    [refetch, activeDropdown]
  );

  const onNavSidebarToggle = useCallback(() => {
    setShowNavSidebar((prev) => !prev);
  }, [setShowNavSidebar]);

  useEffect(() => {
    const newItems = filterNavItems(menuItems, isFeatureEnabled, profile, isAdmin);
    setItems(newItems);
  }, [profile, isFeatureEnabled, featureFlags, isAdmin]);

  const onActionClose = React.useMemo(() => {
    return (action: string) => {
      if (action === "login") {
        onCloseLogin();
      } else if (action === "logout") {
        onCloseLogout();
      } else if (action === "profile") {
        if (window) {
          window.history.replaceState(null, "", window.location.pathname);
        }
        onCloseProfile();
      }
    };
  }, [onCloseLogout, onCloseLogin, onCloseProfile]);

  const onLoginRedirect = React.useMemo(() => {
    return async () => {
      try {
        const url = accessURL(`/auth/twitch`) as URL;
        router.push(url.href);
      } catch (error) {
        console.log({ error });
      }
    };
  }, [accessURL, router]);

  const onLoginShow = React.useMemo(() => {
    return (event: React.MouseEvent) => {
      event.preventDefault();
      onOpenLogin();
    };
  }, [onOpenLogin]);

  const onLogoutRedirect = React.useMemo(() => {
    return async () => {
      try {
        router.push("/");
        logout();
      } catch (error) {}
    };
  }, [router, logout]);

  const handleMobileMenuClick = useCallback(
    (name: string) => {
      onToggleDropdown(`dropdown-${name}`);
      router.push(name === "bonuses" ? "/all-bonuses" : `/${name}`);
    },
    [onToggleDropdown, router]
  );

  const handleHomePageRedirect = useCallback(() => {
    router.push("/");
    setActiveDropdown(null);
  }, [router]);

  const Cards = useMemo(() => {
    const cards: React.ReactElement[] = [];
    {
      data.map((activeNotification) => {
        switch (activeNotification.type) {
          case NotificationType.StreamLive:
            cards.push(
              <StreamLiveNotificationCard
                onActionButtonClick={() => setActiveDropdown(null)}
                {...activeNotification}
                key={activeNotification.id}
              />
            );
            break;
          case NotificationType.NewStreamer:
            cards.push(
              <NewStreamerNotificationCard
                onActionButtonClick={() => setActiveDropdown(null)}
                {...activeNotification}
                key={activeNotification.id}
              />
            );
            break;
          case NotificationType.Custom:
            cards.push(
              <CustomNotificationCard
                {...activeNotification}
                onActionButtonClick={() => setActiveDropdown(null)}
                key={activeNotification.id}
              />
            );
            break;
          default:
            cards.push(
              <GenericNotificationCard
                {...activeNotification}
                onActionButtonClick={() => setActiveDropdown(null)}
                key={activeNotification.id}
              />
            );
        }
      });
    }
    return cards;
  }, [data]);
  return (
    <>
      <Header fixed={true}>
        <TopHeaderMenu>
          {isTablet && (
            <NavSidebarToggleBtn onClick={onNavSidebarToggle}>
              <Burger />
            </NavSidebarToggleBtn>
          )}

          <Logo>
            <Link href={"/"} onClick={() => handleHomePageRedirect()}>
              <AdaptiveLogo src={logoSrc} width={isMobile ? 58 : 136} height={36} alt="logo" />
            </Link>
          </Logo>
          <StreamIndicator liveStreams={liveStreams} />

          <Profile>
            {isLoadingUserInfo ? (
              <ProfileLoader>
                <Image
                  className="profile-loader"
                  alt={`profile-image`}
                  src="/images/Profile/watch.png"
                  width={40}
                  height={40}
                />
              </ProfileLoader>
            ) : !profile ? (
              <TheButton preset="dark-blue" onClick={onLoginShow}>
                Login
              </TheButton>
            ) : (
              <DesktopViewProfileMenu onOpenLogout={onOpenLogout} profile={profile} />
            )}
          </Profile>
        </TopHeaderMenu>

        <MobileMenu>
          <>
            {metaMobileMenuItems.map((item, index) => (
              <MobileItems key={`mobile-menu-items_${index}`}>
                {item.name === "games" || item.name === "bonuses" ? (
                  <>
                    <MobileMenuItem
                      key={`mobile-menu-item_${item.name}`}
                      onClick={() => handleMobileMenuClick(item.name)}
                      isActive={activeDropdown === `dropdown-${item.name}`}
                    >
                      <MobileMenuItemIcon>
                        {item.icon && item.icon(activeDropdown === `dropdown-${item.name}`)}
                      </MobileMenuItemIcon>
                      {item.name}
                    </MobileMenuItem>
                  </>
                ) : (
                  <MobileMenuItem
                    isActive={activeDropdown === `dropdown-${item.name}`}
                    key={`mobile-menu-item_${item.name}`}
                  >
                    <input
                      data-toggle
                      onChange={() => onToggleDropdown(`dropdown-${item.name}`)}
                      checked={activeDropdown === `dropdown-${item.name}`}
                      type="checkbox"
                      id={`menu-toggler-${item.name}`}
                    />
                    <label data-button-container htmlFor={`menu-toggler-${item.name}`}>
                      {isLoading && item.name === "news" ? (
                        <DualRingLoader smallRing={true}>
                          <DualRingInner smallRing={true}></DualRingInner>
                        </DualRingLoader>
                      ) : (
                        <>
                          <MobileMenuItemIcon>
                            {item.icon && item.icon(activeDropdown === `dropdown-${item.name}`)}
                          </MobileMenuItemIcon>
                          {item.name}
                        </>
                      )}
                    </label>
                  </MobileMenuItem>
                )}
              </MobileItems>
            ))}

            <ChakraModal
              isOpen={isLoginOpen}
              onClose={() => onActionClose("login")}
              size="xl"
              content={
                <TwitchLoginLayout
                  onClose={() => onActionClose("login")}
                  onLogin={onLoginRedirect}
                />
              }
            />
            <ChakraModal
              size="md"
              isOpen={isLogoutOpen}
              onClose={() => onActionClose("logout")}
              content={
                <LogoutLayout onClose={() => onActionClose("logout")} onLogout={onLogoutRedirect} />
              }
            />
          </>
        </MobileMenu>
      </Header>

      <Slide
        in={
          !!activeDropdown &&
          activeDropdown !== "dropdown-games" &&
          activeDropdown !== "dropdown-bonuses" &&
          isSmallMobile
        }
        direction="top"
      >
        {activeDropdown && (
          <DropDownBack>
            {activeDropdown === "dropdown-browse" ? (
              <>
                <MobileHeaderBlock>
                  <DropNav>
                    {itemsMenu.interactive.map((item, index) => (
                      <NavLink
                        key={index}
                        item={item}
                        itemKeys={itemKeys}
                        setItemKeys={setItemKeys}
                        onNavItemClick={onNavItemClick}
                      />
                    ))}
                  </DropNav>
                  <TreasuryBanner onClick={onNavItemClick} />
                </MobileHeaderBlock>
                <MobileHeaderBlock>
                  <DropNav>
                    {itemsMenu.nonInteractive.map((item, index) => (
                      <NavLink
                        key={index}
                        item={item}
                        itemKeys={itemKeys}
                        setItemKeys={setItemKeys}
                        onNavItemClick={onNavItemClick}
                      />
                    ))}
                  </DropNav>
                </MobileHeaderBlock>
                <MobileHeaderBlock>
                  <DropNav>
                    {addMenuItems.map((item, index) => {
                      return (
                        <NavLink
                          key={`add_menu_items-${index}`}
                          item={item}
                          itemKeys={itemKeys}
                          setItemKeys={setItemKeys}
                          onNavItemClick={onNavItemClick}
                        />
                      );
                    })}
                  </DropNav>
                </MobileHeaderBlock>
                <MobileHeaderBlock
                  style={{
                    borderBottom: "none",
                  }}
                >
                  <DropNav>
                    {metaMenuItems.map((item, index) => {
                      return (
                        <NavLink
                          key={`meta_menu_items-${index}`}
                          item={item}
                          itemKeys={itemKeys}
                          setItemKeys={setItemKeys}
                          onNavItemClick={onNavItemClick}
                        />
                      );
                    })}
                  </DropNav>
                </MobileHeaderBlock>
              </>
            ) : activeDropdown === "dropdown-news" ? (
              <NewsContainer>{Cards}</NewsContainer>
            ) : activeDropdown === "dropdown-profile" ? (
              <>
                {profile ? (
                  <MobileViewProfileMenu
                    onOpenLogout={onOpenLogout}
                    profile={profile}
                    handleToggleMobileDropdown={() => setActiveDropdown(null)}
                  />
                ) : null}
                <AuthBlock>
                  {profile ? (
                    <LogoutButton onClick={onOpenLogout}>Log Out</LogoutButton>
                  ) : (
                    <SignInButton onClick={onLoginShow}>Sign in</SignInButton>
                  )}
                </AuthBlock>
              </>
            ) : null}
          </DropDownBack>
        )}
      </Slide>
    </>
  );
};

const ProfileLoader = styled.div`
  display: flex;
  font-family: var(--font-family-golos);
  gap: 5px;
  align-items: center;
  justify-content: center;
  .profile-loader {
    animation: tilt-n-move-shaking 0.7s linear infinite;
    animation-fill-mode: forwards;
  }
  @media (max-width: 1480px) {
    display: none;
  }
  @keyframes tilt-n-move-shaking {
    0% {
      transform: translate(0, 0) rotate(0deg);
    }
    25% {
      transform: translate(5px, 5px) rotate(5deg);
    }
    50% {
      transform: translate(0, 0) rotate(0eg);
    }
    75% {
      transform: translate(-5px, 5px) rotate(-5deg);
    }
    100% {
      transform: translate(0, 0) rotate(0deg);
    }
  }
`;

const NewsContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  align-items: center;
`;
const NavSidebarToggleBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--clouds-100);

  svg {
    opacity: 0.8;
    transition: opacity 0.3s;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

const HeaderComponent = withApp(HeaderComponentPure);

export default HeaderComponent;
